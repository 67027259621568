import React from 'react'
import style from './css/header.module.css'

import careerquest from '../../assets/career2020.JPG'

const Header = () => {
  return (
    <>
        <div className={style.headerImageContainer}>
            <img src={careerquest} alt='career quest 2020' className={style.headerImage} />
        </div>
        <div className={style.aboutContainer}>
        <header className={style.aboutHeader}>
        <h2 className={style.aboutTitle}>About Aptech Lekki Centre</h2>
            <div className={style.aboutColumn}>
                <p className={style.aboutMainText}>Aptech Lekki Centre is a Franchise of Aptech Limited, a Global Learning Solutions Company with a presence across 5 continents, is playing a key role in helping individuals, organizations and nations adapt to the changing requirements of a knowledge-driven world. Aptech commenced its IT education & training business in 1986 and has trained over 7 million students globally.</p>
                <p className={style.aboutMainText}>Aptech is an ISO 9001:2000 organization and was the first IT training and education organization in Asia to receive the ISO 9001 quality certification for Education Support Services in 1993. We have been awarded with ISO27001 for our LEARNING SERVICES and ATTEST divisions and are CMMI Level 3 certified for content development.</p>
                <p className={style.aboutMainText}>As a pioneer and leader in the field of IT education, Aptech has amassed a rich experience of over 31 years in the field. The company develops and deploys learning solutions for its clientele ranging from young school children to working IT professionals to major Fortune 500 companies. Aptech offers a wide range of solutions across various segments.</p>
            </div>
        </header>
    </div>
    </>
  )
}

export default Header