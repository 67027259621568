import React from 'react';
import style from './css/services.module.css';

import certificate from '../../assets/Certificate.png';
import graduationcap from '../../assets/Graduation-Cap.png';

const Services = () => {
  return (
    <section className={style.servicesContainer}>
        <header className={style.servicesHeader}>
            <h2 className={style.servicesTitle}>Our Services</h2>
            <p className={style.servicesText}>Are you contemplating a career change or seeking to acquire new skills? Allow us to assist you in making informed decisions and presenting various pathways for you to revitalize your career journey for optimal success.</p>
        </header>
        <div className={style.servicesCards}>
            <div className={style.servicesCard}>
                <img src={certificate} alt="Certificate"/>
                <h3 className={style.cardTitle}>International Certifications</h3>
                <p>Students can earn an Advanced Diploma in Software Engineering (ADSE), a highly esteemed qualification recognized by employers and universities around the globe, through Aptech. With a rich history of over 30 years in IT training, Aptech is a trusted partner for career development. Aptech collaborates with NCC Education and esteemed institutions such as Middlesex, UCLAN, and Lincoln, providing students with the opportunity to attain globally recognized Bachelor's degrees.</p>
            </div>
            <div className={style.servicesCard}>
                <img src={graduationcap} alt="Graduation Cap"/>
                <h3 className={style.cardTitle}>Access to 24/7 E-learning</h3>
                <p>Online Varsity opens the door to exclusive e-learning opportunities, ensuring 24/7 access from any location, on any device, at your convenience. Discover interactive tools, invaluable resources, expert advice, and guidance tailored to enhance students' skills beyond the confines of the traditional classroom.</p>
            </div>
        </div>
    </section>
  )
}

export default Services 
