import React from 'react';
import { Link } from 'react-router-dom';
import style from './css/interest.module.css'

// images
import ui from '../../assets/Windows-10-Personalization.png'
import data from '../../assets/DataBackup.png'
import ai from '../../assets/ScienceFiction.png'
import web from '../../assets/WebDesign.png'

const Interest = () => {
  return (
    <section className={style.interestContainer}>
        <div className={style.interestRow}>
            <h2 className={style.interestTitle}>Are you interested in  learning in-demand IT skills?</h2>
            <div className={style.interestColumn}>
                <p className={style.interestMainText}>Our well crafted industry standard courses will give you a competitive edge in the tech industry and you will get to build real world projects curated by our professional instructors.</p>
                <Link exact='true' to='/courses' className={style.link}>More courses</Link>
            </div>
        </div>
        <div className={style.cards}>
                <div className={style.card}>
                    <img src={ui} alt="UI/UX" />
                    <p className={style.bigText}>UI/UX</p>
                </div>
                <div className={style.card}>
                    <img src={ai} alt="Artificial intelligence" />
                    <p className={style.bigText}>AI</p>
                </div>
                <div className={style.card}>
                    <img src={web} alt="Web Design" />
                    <p className={style.bigText}>Web</p>
                </div>
                <div className={style.card}>
                    <img src={data} alt="Database Management" />
                    <p className={style.bigText}>Data</p>
                </div>
        </div>
    </section>
  )
}

export default Interest