import React, { useState, useEffect } from 'react';
import style from './Typewriter.module.css'

const Typewriter = ({ text, typingDelay = 100, deletingDelay = 50, loopDelay = 2000 }) => {
    const [typedText, setTypedText] = useState('');
    const [isDeleting, setIsDeleting] = useState(false);
    const [index, setIndex] = useState(0);

    useEffect(() => {
        let timer;

        const handleTyping = () => {
            setTypedText(currentText => currentText + text.charAt(index));
            setIndex(index + 1);
        };

        const handleDeleting = () => {
            setTypedText(currentText => currentText.slice(0, -1));
        };

        if (isDeleting) {
            if (typedText.length === 0) {
                setIsDeleting(false);
                setIndex(0);
                timer = setTimeout(() => {}, loopDelay);
            } else {
                timer = setTimeout(handleDeleting, deletingDelay);
            }
        } else {
            if (index < text.length) {
                timer = setTimeout(handleTyping, typingDelay);
            } else {
                timer = setTimeout(() => setIsDeleting(true), loopDelay);
            }
        }

        return () => clearTimeout(timer);
    }, [typedText, isDeleting, index, text, typingDelay, deletingDelay, loopDelay]);


  return (
    <div className={style.typewriter}>
        <span>{typedText}</span>
        <span className={style.cursor}></span>
    </div>
  )
}

export default Typewriter