import React, { useState, useRef } from 'react'
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import style from './Applicationform.module.css'


const ApplicationForm = () => {
    const form = useRef();

    const [formData, setFormData] = useState({
        fullname: '',
        gender: '',
        email: '',
        phoneNumber: '',
        course: ''
    });
    const [errors, setErrors] = useState({});

    const validate = () => {
        let tempErrors = {};
        tempErrors.fullname = formData.fullname ? "" : "Full name is required.";
        tempErrors.email = formData.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? "" : "Email is not valid.";
        tempErrors.phoneNumber = formData.phoneNumber.length > 9 ? "" : "Phone number is not valid.";
        tempErrors.gender = formData.gender ? "" : "Gender is required.";
        tempErrors.course = formData.course ? "" : "Course is required.";
        setErrors(tempErrors);
        return Object.values(tempErrors).every(x => x === "");
    }

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {

        emailjs.sendForm('service_4dilywu', 'template_2rpr6sg', form.current, 'vBevDFCwhM18ID87S')
        .then((result) => {
            // console.log(result.text);
            toast.success('Application sent successfully')
            // reset form data
            setFormData({
                fullname: '',
                gender: '',
                email: '',
                phoneNumber: '',
                course: ''
            });
        }, (error) => {
            // console.log(error.text);
            toast.error('Ooops! Application unsuccessful')
        });
    }
    };

    return (
        <div className={style.formContainer}>
            <ToastContainer />
            <form ref={form} className={style.applicationForm} onSubmit={handleSubmit}>
                <h2>Application Form</h2>
                <p>It is time to take that bold step into your tech career journey... <span>no gree for anybody</span></p>
                <input 
                    type="text" 
                    name="fullname" 
                    placeholder="Full Name" 
                    value={formData.fullname} 
                    onChange={handleInputChange} 
                />
                {errors.fullname && <div className={style.error}>{errors.fullname}</div>}
                
                <select name="gender" value={formData.gender} onChange={handleInputChange}>
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                </select>
                {errors.gender && <div className={style.error}>{errors.gender}</div>}

                <input 
                    type="email" 
                    name="email" 
                    placeholder="Email" 
                    value={formData.email} 
                    onChange={handleInputChange} 
                />
                {errors.email && <div className={style.error}>{errors.email}</div>}

                <input 
                    type="tel" 
                    name="phoneNumber" 
                    placeholder="Phone Number" 
                    value={formData.phoneNumber} 
                    onChange={handleInputChange} 
                />
                {errors.phoneNumber && <div className={style.error}>{errors.phoneNumber}</div>}

                <select name="course" value={formData.course} onChange={handleInputChange}>
                    <option value="">Select Course</option>
                    <option value="software_engineering">Advanced Diploma in Software Engineering</option>
                    <option value="basics">Basics (MS Office Suite)</option>
                    <option value="cyber_security">Cyber Security</option>
                    <option value="c#">C#</option>
                    <option value="data_management">Data Management (SQL Server)</option>
                    <option value="data_science">Data Science</option>
                    <option value="data_analysis">Data Analysis</option>
                    <option value="digital_marketing">Digital Marketing</option>
                    <option value="ethical_hacking">Ethical Hacking</option>
                    <option value="frontend">Frontend</option>
                    <option value="graphics_design">Graphics Design</option>
                    <option value="java">Java</option>
                    <option value="project_management">Project Management</option>
                    <option value="python">Python Programming</option>
                    <option value="ui_ux">UI/UX/Product Design</option>
                    <option value="web_development">Web Development (Full Stack)</option>
                </select>
                {errors.course && <div className={style.error}>{errors.course}</div>}

                <button type="submit">Submit</button>
            </form>
        </div>
    );
};

export default ApplicationForm