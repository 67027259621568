import React from 'react'

import style from './css/hero.module.css'

import image1 from '../../assets/networker.webp'

const Hero = () => {
  return (
    <div className={style.heroContainer}>
        <h1>Our Career Courses</h1>
        <p>A career in programming is not bound by age or gender, making it an accessible option for individuals of all backgrounds. One of the most compelling reasons to pursue a career in computer programming is the abundance of job opportunities available. Given our society's growing dependence on technology, it's evident that the programming sector offers enduring job prospects. While other industries may decline, programming continues to thrive and extend its influence across various aspects of daily life.</p>
        <img src={image1} alt='Network engineer'/>
    </div>
  )
}

export default Hero