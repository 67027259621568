import React from 'react';
import style from './css/contactdetails.module.css'; 
import ContactForm from './ContactForm';

const ContactDetails = () => {
    return (
        <>
        <div className={style.contactDetailsContainer}>
            <div className={style.contactDetails}>
                <header className={style.contactMessage}>
                    <h3>Contact Us</h3>
                    <p>Use the enquiry form for all information requests or contact us directly using the contact information below.</p>
                    <p>Feel free to call our mobile number as well.</p>
                </header>
                <div className={style.contactInfo}>
                    <p><b className={style.titleText}>Phone:</b><br /> <span className={style.text}>+2348134370701, +2347032734515</span></p>
                    <p><b className={style.titleText}>Email:</b><br /> <span className={style.text}>info@aptechlekki.com, aptechlekki@gmail.com</span></p>
                    <p><b className={style.titleText}>Address:</b><br /> <span className={style.text}>2nd Floor, Brasas "R" Place Admiralty Way, Lekki Phase 1, Lagos - Nigeria.</span></p>
                    <p><b className={style.titleText}>Open:</b><br /> 8:00 AM - 5:00 PM (Mon - Fri)</p>
                </div>
            </div>
            <ContactForm />
        </div>
            <div>
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7929.166829477299!2d3.471941!3d6.447496000000001!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103bf5d4ffffffff%3A0x3182f2425772ccf!2sAptech%20Computer%20Education%20Lekki%20Centre!5e0!3m2!1sen!2sng!4v1705579568107!5m2!1sen!2sng" title="map" width="100%" height="450"   allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
       </>
    );
};

export default ContactDetails;