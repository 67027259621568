import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import Home from './pages/home/Home';
import About from './pages/about/About';
import Courses from './pages/courses/Courses';
import Contact from './pages/contact/Contact';
import Events from './pages/events/Events';
import Apply from './pages/apply/Apply';
import './App.css';
import Nav from './layout/Nav';
import Footer from './layout/Footer';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Nav />
        <Routes>
          <Route exact path="/" element={<Home/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/courses" element={<Courses/>} />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/events" element={<Events/>} />
          <Route path="/apply" element={<Apply/>} />
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
