import React from 'react'
import style from './css/training.module.css'
import desktop from '../../assets/Laptop-Cleaning.png'

import { FaArrowRightLong } from "react-icons/fa6";

const Training = () => {

    const jobs = [['UI/UX Designer', 'Web Designer', 'Mobile App Developer', 'Full Stack Web Developer', 'Backend Developer', 'Frontend Developer', 'Certified Microsoft Office Operator'], ['Data Analyst', 'Data Scientist', 'AI/Machine Learning Engineer', 'Database Administrator', 'Digital Marketer', 'Project Manager', 'Cyber Security']]

    const renderList = (jobs, index) => (
        <ul key={index} className={style.trainingList}>
            {jobs.map((job, idx) => <li key={idx} className={style.item}>{job}<FaArrowRightLong color="#A60000" className={style.arrowIcon} /></li>)}
        </ul>
    );

  return (
    <section className={style.trainingContainer}>
        <header className={style.trainingHeader}>
            <img src={desktop} alt="Desktop computer" />
            <h2 className={style.trainingTitle}>We offer training for every role.</h2>
        </header>
        <div className={style.trainingRow}>
            {jobs.map((job, index) => renderList(job, index))}
        </div>
    </section>
  )
}

export default Training