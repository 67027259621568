import React from 'react'
import style from './TeamMemberCard.module.css'

const TeamMemberCard = ({ image, alternative, name, designation }) => {
  return (
    <div className={style.teamMemberCard}>
            <div className={style.cardContent}>
                <img src={image} alt={alternative} />
                <h3>{name}</h3>
                <p>{designation}</p>
            </div>
    </div>
  )
}

export default TeamMemberCard