import React from 'react'

import style from './css/certifications.module.css'

import { AiFillSafetyCertificate, AiOutlineSafetyCertificate } from "react-icons/ai";
import { PiCertificate } from "react-icons/pi";

const Certifications = () => {
  return (
    <div className={style.certificationsContainer}>
        <div className={style.certificationsInnerContainer}>
            <div>
                <AiFillSafetyCertificate className={style.certificateIcon} />
                <h3>Semester 1</h3>
                <p>Certificate of Proficiency in Information Systems Management (CPISM)</p>
            </div>
            <div>
                <AiOutlineSafetyCertificate className={style.certificateIcon} />
                <h3>Semester 1 + 2</h3>
                <p>Diploma in Information Systems Management (DISM)</p>
            </div>
        </div>
        <div className={style.courseAdse}>
            <PiCertificate className={style.certificateIcon} />
            <h3>Semester 1 + 2 + 3 + 4</h3>
            <p>Advanced Diploma in Software Engineering (ADSE)</p>
        </div>
    </div>
  )
}

export default Certifications