import React from 'react'
import TestimonialCarousel from '../Carousel/TestimonialCarousel'

import style from './css/testimonials.module.css'

const Testimonials = () => {
  return (
    <section className={style.testimonialsContainer}>
        <header className={style.testimonialsHeader}>
            <h2 className={style.testimonialsTitle}>Testimonials</h2>
            <p className={style.testimonialsText}>Hear what our students have to say about Aptech Lekki Centre</p>
        </header>
        <iframe width="100%" height="400" className={style.testimonialsYoutube} src="https://www.youtube.com/embed/Kth_PArzTlI" title="Student Testimonials" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        <TestimonialCarousel />
    </section>
  )
}

export default Testimonials