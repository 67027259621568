import React from 'react'
import style from './css/courseoptions.module.css'

const CourseOptions = () => {
  return (
    <div>
    <div className={style.courseOptionContainer}>
        <div className={style.courseOptionInnerContainer}>
            <div className={style.otherCourses}>
                <h3>Professional Courses</h3>
                <ul>
                    <li>Artificial Intelligence (AI)</li>
                    <li>Web Developmemt with Python or PHP</li>
                    <li>Backend/Frontend Web Development</li>
                    <li>Responsive Web Development </li>
                    <li>Data Science </li>
                    <li>Programming Languages (Python, Java, C#, C++) </li>
                    <li>Ethical Hacking </li>
                </ul>
            </div>
            <div>
                <h3>Corporate Training</h3>
                <ul>
                    <li>Database Management/SQL Server</li>
                    <li>MS Office Suite (Advanced Excel)</li>
                    <li>Backend/Frontend Web Development</li>
                    <li>Programming Languages (Python, Java, C#, C++)</li>
                </ul>
            </div>
        </div>
        <div className={style.courseAdse}>
            <h3>Career Course</h3>
            <p>Advanced Diploma in Software Engineering (ADSE)</p>
            <p>Diploma in Information Systems Management (DISM)</p>
            <p>Certificate of Proficiency in Information Systems Management (CPISM)</p>
        </div>
    </div>
    </div>
  )
}

export default CourseOptions