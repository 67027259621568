import React from 'react'
import TeamMemberCard from '../TeamMemberCard/TeamMemberCard';

import style from './css/team.module.css'

import director from '../../assets/director1.jpg'
import kate from '../../assets/kateebinum.jpeg'
import adegbite from '../../assets/mr_adegbite.jpg'
import casmir from '../../assets/mr-casmir.png'
import james from '../../assets/james.jpeg'
import joy from '../../assets/joy.jpeg'
import monisola from '../../assets/monisola2.jpeg'
import emmanuel from '../../assets/emmanuel.jpeg'
import michael from  '../../assets/michael.jpeg'

const Team = () => {
    const teamMembers = [
        { image: director, altText: "Mr. Joseph Ebinum", name: "Mr. Joseph Ebinum", designation: "Director" },
        { image: kate, altText: "Mrs. Kate Ebinum", name: "Mrs. Kate Ebinum", designation: "Executive Director" },
        { image: adegbite, altText: "Mr. Olusegun Adegbite", name: "Mr. Olusegun Adegbite", designation: "Centre Manager" },
        { image: casmir, altText: "Mr. Casmir Adumekwe", name: "Mr. Casmir Adumekwe", designation: "Centre Academic Head" },
        { image: james, altText: "Mr. James Ishaku", name: "Mr. James Ishaku", designation: "Deputy Centre Academic Head" },
        { image: monisola, altText: "Ms. Monisola Fanisi", name: "Ms. Monisola Fanisi", designation: "Academic Counsellor" },
        { image: joy, altText: "Mrs. Joy Edobor", name: "Mrs. Joy Edobor", designation: "Marketing Executive" },
        { image: michael, altText: "Mr. Michael Cousin", name: "Mr. Michael Cousin", designation: "Faculty" },
        { image: emmanuel, altText: "Mr. Ikyor Terna Emmanuel", name: "Mr. Ikyor Terna Emmanuel", designation: "Faculty" }, 
    ];

  return (
    <section className={style.teamContainer}>
        <div className={style.headerContainer}>
            <header className={style.teamHeader}>
                <h2 className={style.teamTitle}>Our Team</h2>
                <p className={style.teamMainText}>This is who we are</p>
            </header>
            <div className={style.teamCardContainer}>
            {teamMembers.map((member, index) => (
                <TeamMemberCard key={index} image={member.image} alternative={member.altText} name={member.name} designation={member.designation} />
            ))}
        </div>
        </div>
    </section>
  )
}

export default Team