import React, { useState, useRef, useEffect } from 'react'
import style from './css/faq.module.css'

import { IoChevronDownOutline } from "react-icons/io5";

const FAQ = ({ question, answer }) => {
    const [active, setActive] = useState(false)

    const toggleAccordion = () => {
        setActive(!active)
    }
    const contentRef = useRef(null)

    useEffect(() => {
        contentRef.current.style.maxHeight = active
          ? `${contentRef.current.scrollHeight}px`
          : '0px'
      }, [contentRef, active])
    
      useEffect(() => {
        contentRef.current.style.maxHeight = active
          ? `${contentRef.current.scrollHeight}px`
          : '0px'
      }, [contentRef, active])

  return (
    <section className={style.accordionSection}>
        <div className={style.FaqAccordion}>
        <div
          className={`${style.questionSection} ${active}`}
          onClick={toggleAccordion}
          aria-hidden='true'
        >
          <div
            className={style.FaqQuestionContainer}
            style={
              active
                ? { background: '#A60000', color: '#fff' }
                : {
                    background: '#FFBA00',
                    transition: 'all 500ms ease-in-out',
                  }
            }
          >
            <p className={style.questionStyle}>{question}</p>
            <IoChevronDownOutline
              className={active ? `${style.rotate}` : `${style.normal}`}
            />
          </div>
          <div className={style.answerContainer} ref={contentRef}>
            <div className={style.answerContent}>
              <p
                className={
                  active ? `${style.displayAnswer}` : `${style.hideAnswer}`
                }
              >
                {answer}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FAQ