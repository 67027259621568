import React from 'react'

import style from './css/partners.module.css'

// images
import middlesex from '../../assets/middlesex-university-logo.png'
import ncc from '../../assets/ncc-logo.png'
import uclan from '../../assets/UCLAN.jpg'
import ofqual from '../../assets/ofqual.jpg'
import lincoln from '../../assets/lincoln.jpg'
import bolton from '../../assets/bolton.png'

const Partners = () => {
  return (
    <section className={style.partnersContainer}>
        <div className={style.partnersRow}>
            <header className={style.partersHeader}>
                <h2 className={style.partnersTitle}>Our Partners</h2>
                <p className={style.partnersMainText}>Our brand has fostered amazing partnership with top universities and tech industries over the years...</p>
            </header>
            <div className={style.partnersColumn}>
                <table className={style.partnersTable}>
                <tbody>
                    <tr>
                        <td><img src={middlesex} alt='Middlesex University' className={style.pics}/></td>
                        <td><img src={ncc} alt='NCC' className={style.pics} /></td>
                        <td><img src={uclan} alt='Uclan' className={style.pics} /></td>
                    </tr>
                    <tr>
                        <td><img src={ofqual} alt='Ofqual' className={style.pics} /></td>
                        <td><img src={lincoln} alt='Lincoln University' className={style.pics} /></td>
                        <td><img src={bolton} alt='University of Bolton' className={style.pics} /></td>
                    </tr>
                </tbody>
                </table>
            </div>
        </div>
    </section>
  )
}

export default Partners