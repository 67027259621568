import React from 'react'
import { Helmet } from "react-helmet";
import Header from '../../components/About/Header'
import FAQSection from '../../components/About/FAQSection'
import Team from '../../components/About/Team'


const About = () => {
  return (
    <>
      <Helmet>
         {/* Google tag (gtag.js) for Ad metrics */}
         <script async src="https://www.googletagmanager.com/gtag/js?id=AW-671901233"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-671901233');
          `}
        </script>
          {/* Google tag (gtag.js) for Ad metrics */}
      <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta
      name="description"
      content="Aptech Lekki Centre is a franchise of Aptech Computer Education India. We give hands-on training in software engineering: web development, Mobile and Desktop app development and basic computer operation in Lekki Nigeria. We build high performing developers through our rich and outstanding curriculum."
    />
    <meta
      name="keywords"
      content="Learn Programming, learn web development in Nigeria, Become a software engineer, Learn mobile application development, frontend development, backend development, Learn HTML, Learn CSS, Learn JavaScript, Learn Python, Learn C#, Learn Java, SQL, Project Management, Digital Marketing"
    />

    <meta
      property="og:title"
      content="Aptech Lekki Centre | About Us"
    />
    <meta property="og:url" content="https://aptechlekki.com/" />
    <meta
      property="og:description"
      content="Aptech Lekki Centre is a franchise of Aptech Computer Education India. We give hands-on training in software engineering: web development, Mobile and Desktop app development and basic computer operation in Lekki Nigeria. We build high performing developers through our rich and outstanding curriculum."
    />

    <meta name="robots" content="index, follow" />
      </Helmet>
      <Header />
      <FAQSection />
      <Team />
    </>
  )
}

export default About