import React from 'react';
import { Helmet } from "react-helmet"; 
import Hero from '../../components/Home/Hero';
import Services from '../../components/Home/Services';
import Interest from '../../components/Home/Interest';
import Training from '../../components/Home/Training';
import Testimonials from '../../components/Home/Testimonials';
import Partners from '../../components/Home/Partners';

const Home = () => {
  return (
    <>
      <Helmet>  
         {/* Google tag (gtag.js) for Ad metrics */}
         <script async src="https://www.googletagmanager.com/gtag/js?id=AW-671901233"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-671901233');
          `}
        </script>

        {/* Google Tag Manager */}
        <script>
          {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-TJ53RH2N');
          `}
        </script>
        {/* End Google Tag Manager */}
        
        <html lang="en" />  
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="description"
          content="Aptech Lekki Centre is a franchise of Aptech Computer Education India. We give hands-on training in software engineering: web development, Mobile and Desktop app development and basic computer operation in Lekki Nigeria. We build high performing developers through our rich and outstanding curriculum."
        />
        <meta
          name="keywords"
          content="Aptech Chevron, Aptech VI, Aptech Ajah, Aptech Lekki, Learn Programming, learn web development in Nigeria, Become a software engineer, Learn mobile application development, frontend development, backend development, Learn HTML, Learn CSS, Learn JavaScript, Learn Python, Learn C#, Learn Java, SQL, Project Management, Digital Marketing"
        />
        <meta property="og:title" content="Aptech Lekki Centre | Computer Education"/>
        <meta property="og:url" content="https://aptechlekki.com/" />
        <meta property="og:description" content="Aptech Lekki Centre is a franchise of Aptech Computer Education India. We give hands-on training in software engineering: web development, Mobile and Desktop app development and basic computer operation in Lekki Nigeria. We build high performing developers through our rich and outstanding curriculum."/>

        <meta name="robots" content="index, follow" />

        <title>Aptech Lekki Centre | Computer Education</title>    
        <meta name="theme-color" content="#FFBA00" />  
      </Helmet>  

      {/* Google Tag Manager (noscript) */}
      <noscript>
        <iframe 
          src="https://www.googletagmanager.com/ns.html?id=GTM-TJ53RH2N"
          height="0" 
          width="0" 
          style={{ display: 'none', visibility: 'hidden' }} 
          title="Google Tag Manager"
        ></iframe>
      </noscript>
      {/* End Google Tag Manager (noscript) */}
      
      <Hero />
      <Services />
      <Interest />
      <Training />
      <Testimonials />
      <Partners />
    </>
  )
}

export default Home;