import React from 'react'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import style from './TesimonialCarousel.module.css'

// images
import kunle from '../../assets/kunle.jpg'
import blessing from '../../assets/Blessing-Michael.jpg'
import timi from '../../assets/timi.jpg'

const TestimonialCarousel = () => {
  return (
    <Carousel
      showArrows={true}
      infiniteLoop={true}
      showThumbs={false}
      showStatus={false}
      autoPlay={true}
      interval={4000}
    >
      <div>
        <img src={timi} alt='Davies Emmanuel Etim' className={style.img} />
        <div className={style.myCarousel}>
          <h3>Davies Emmanuel Etim</h3>
          <p>
          Aptech has always believed in helping and guiding students in discovering their career paths, my years spent here have always been full of learning opportunities that were fun-filled. I found the courses interesting, challenging and rewarding.
          </p>
        </div>
      </div>

      <div>
        <img src={kunle} alt='KUNLE-LAWANSON OLUWAFEMI' className={style.img} />
          <div className={style.myCarousel}>
          <h3>Kunle-Lawanson Oluwafemi</h3>
          <p>
          It's been an awesome journey with Aptech Lekki, Aptech will always say it's not a University but I assure prospective students to get ready to learn like a University.
          </p>
        </div>
      </div>

      <div>
        <img src={blessing} alt='Blessing Michael' className={style.img} />
        <div className={style.myCarousel}>
          <h3>Blessing Michael</h3>
          <p>
          I would like to thank my faculty at Aptech Lekki Centre for helping me to understand more and also giving me the exposure in the I.T world...
          </p>
        </div>
      </div>
    </Carousel>
  )
}

export default TestimonialCarousel