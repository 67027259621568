import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import style from './css/contactForm.module.css'

import { FaPaperPlane } from "react-icons/fa";

const ContactForm = () => {
    const form = useRef();

    const [formData, setFormData] = useState({
        fullname: '',
        gender: '',
        email: '',
        phoneNumber: '',
        message: ''
    });
    const [errors, setErrors] = useState({});

    const validate = () => {
        let tempErrors = {};
        tempErrors.fullname = formData.fullname ? "" : "Full name is required.";
        tempErrors.email = formData.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? "" : "Email is not valid.";
        tempErrors.phoneNumber = formData.phoneNumber.length > 9 ? "" : "Phone number is not valid.";
        tempErrors.message = formData.message ? "" : "Message is required.";
        setErrors(tempErrors);
        return Object.values(tempErrors).every(x => x === "");
    }

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            // console.log(formData); // Form is valid, submit data

            emailjs.sendForm('service_4dilywu', 'template_9rv3pji', form.current, 'vBevDFCwhM18ID87S')
            .then((result) => {
                // console.log(result.text);
                toast.success('Message sent successfully')
                // reset form data
                setFormData({
                    fullname: '',
                    gender: '',
                    email: '',
                    phoneNumber: '',
                    message: ''
                });
            }, (error) => {
                toast.success('Oops! Message failed')
                // console.log(error.text);
            });
        }

    };

    return (
        <div className={style.formContainer}>
            <ToastContainer />
            <form ref={form} className={style.applicationForm} onSubmit={handleSubmit}>
                <h2>Enquiry Form</h2>
                <p>Drop a message and we will get back to you as soon as possible.</p>
                <input 
                    type="text" 
                    name="fullname" 
                    placeholder="Full Name" 
                    value={formData.fullname} 
                    onChange={handleInputChange} 
                />
                {errors.fullname && <div className={style.error}>{errors.fullname}</div>}
        

                <input 
                    type="email" 
                    name="email" 
                    placeholder="Email" 
                    value={formData.email} 
                    onChange={handleInputChange} 
                />
                {errors.email && <div className={style.error}>{errors.email}</div>}

                <input 
                    type="tel" 
                    name="phoneNumber" 
                    placeholder="Phone Number" 
                    value={formData.phoneNumber} 
                    onChange={handleInputChange} 
                />
                {errors.phoneNumber && <div className={style.error}>{errors.phoneNumber}</div>}

                <textarea placeholder='Message' name='message' value={formData.message} onChange={handleInputChange}></textarea>
                {errors.message && <div className={style.error}>{errors.message}</div>}

                <button type="submit">Send Message <FaPaperPlane /></button>
            </form>
        </div>
    );
};

export default ContactForm;