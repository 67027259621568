import React from 'react'

import style from './css/course.module.css'

const semester1 = ['Programming Principles and Techniques', 'Logic Building and Elementary Programming', 'Building Modern Websites', 'UI/UX for Responsive Web Design', 'Frontend Web Development with React', 'eProject - Responsive Website Development', 'Object-oriented Programming Concepts', 'Data Management with SQL Server']

const semester2 = ['Data Processing with XML and JSON', 'Java Programming - I', 'Java Programming - II', 'Project - Java Application Development', 'Programming in C#', 'Emerging Job Areas (SMAC)', 'Managing Large Data Sets with MongoDB']

const semester3A = ['Web Component Development using Jakarta EE', 'Building Java Web Applications with Spring Framework', 'Introduction to Dart Programming', 'Application Development using Flutter and Dart', 'Agile and DevOps', 'eProject - Cross Platform App Development']

const semester3B = ['Developing ASP.NET Core MVC Applications', 'Introduction to Dart Programming', 'Application Development using Flutter and Dart', 'Agile and DevOps', 'eProject - Cross Platform App Development']

const semester4A = ['Working with MySQL', 'Developing  Application with Python', 'Web Application Development using Python', 'Web Framework for Python (Django)', 'eProject (Python - Web Application Development)', 'Enterprise Application Development in Jakarta EE', 'Creating Services for the Web', 'Software Engineering Principles', 'Software Project Management']

const semester4B = ['Working with MySQL', 'Developing  Application with Python', 'Web Application Development using Python', 'Web Framework for Python (Django)', 'eProject (Python - Web Application Development)', 'Developing Microsoft Azure Solutions', 'Software Engineering Principles', 'Software Project Management']

const semester4C = ['Working with MySQL', 'Developing  Application with Python', 'Web Application Development using Python', 'Web Framework for Python (Django)', 'eProject (Python - Web Application Development)', 'Oracle Database SQL', 'Program with PL/SQL', 'Oracle Database Administration I', 'Oracle Database Administration II']

const semester4D = ['Working with MySQL', 'Developing  Application with Python', 'Web Application Development using Python', 'Web Framework for Python (Django)', 'eProject (Python - Web Application Development)', 'Azure Fundamentals', 'Implementing, Managing and Monitoring Azure Environment', 'Azure Architect Technologies', 'Azure Architect Design']

const semester4E = ['Developing  Application with Python', 'Inferential Statistical Analysis', 'AI Primer', 'AI Application of NLP', 'AI and ML with Python', 'Applied Machine Learning with Python', 'Deep Learning using Neural Networks', 'Capstone Project - Recommendation Engine and Customer Churn Prediction']

const semester4F = ['Application-based Programming with Python', 'Data Mining with WEKA', 'Data Science using R Programming', 'Data Science with Python', 'Foundation of Big Data Systems with Hadoop', 'Advanced Data Science with Python', 'Visual Analytics with Tableau', 'Capstone Project for Data Science']

const semester4G = ['Application-based Programming with Python', 'IoT Hardware', 'IoT Networking', 'Programming the IoT with Python', 'Capstone Project for IoT']

const Course = () => {

  return (
    <section className={style.courseContainer}>
    {/* Semester 1 */}
        <header className={style.courseHeader}>
            <h2 className={style.courseTitle}>Semester 1</h2>
        </header>
        <div className={style.courseRow}>
            <ul className={style.listRow}>
                {semester1.map((course, index) => (
                    <li key={index}>{course}</li>
                ))}
            </ul>
            <div className={style.courseDuration}>
                <div className={style.courseHours}>
                    <span>Duration : </span>
                    <h3>178<br />Hours</h3>
                </div>
                <div className={style.courseOpportunities}>
                    <p><span>Job Opportunities:</span> Responsive Website Developer, C Programmer</p>
                </div>
            </div>
        </div>

    {/* Semester 2 */}
        <header className={style.courseHeader}>
            <h2 className={style.courseTitle}>Semester 2</h2>
        </header>
        <div className={style.courseRow}>
            <ul className={style.listRow}>
                {semester2.map((course, index) => (
                    <li key={index}>{course}</li>
                ))}
            </ul>
            <div className={style.courseDuration}>
                <div className={style.courseHours}>
                    <span>Duration : </span>
                    <h3>170<br />Hours</h3>
                </div>
                <div className={style.courseOpportunities}>
                    <p><span>Job Opportunities:</span> Java Application Programmer, .NET Application Programmer</p>
                </div>
            </div>
        </div>

    {/* Semester 3a */}
        <header className={style.courseHeader}>
            <h2 className={style.courseTitle}>Semester 3A (Java)</h2>
        </header>
        <div className={style.courseRow}>
            <ul className={style.listRow}>
                {semester3A.map((course, index) => (
                    <li key={index}>{course}</li>
                ))}
            </ul>
            <div className={style.courseDuration}>
                <div className={style.courseHours}>
                    <span>Duration : </span>
                    <h3>146<br />Hours</h3>
                </div>
                <div className={style.courseOpportunities}>
                    <p><span>Exit:</span> Not Available</p>
                </div>
            </div>
        </div>

    {/* Semester 3b */}
        <header className={style.courseHeader}>
            <h2 className={style.courseTitle}>Semester 3B (.net)</h2>
        </header>
        <div className={style.courseRow}>
            <ul className={style.listRow}>
                {semester3B.map((course, index) => (
                    <li key={index}>{course}</li>
                ))}
            </ul>
            <div className={style.courseDuration}>
                <div className={style.courseHours}>
                    <span>Duration : </span>
                    <h3>122<br />Hours</h3>
                </div>
                <div className={style.courseOpportunities}>
                    <p><span>Exit:</span> Not Available</p>
                </div>
            </div>
        </div>

    {/* Semester 4a */}
        <header className={style.courseHeader}>
            <h2 className={style.courseTitle}>Semester 4A (OST & Java)</h2>
        </header>
        <div className={style.courseRow}>
            <ul className={style.listRow}>
                {semester4A.map((course, index) => (
                    <li key={index}>{course}</li>
                ))}
            </ul>
            <div className={style.courseDuration}>
                <div className={style.courseHours}>
                    <span>Duration : </span>
                    <h3> 246<br />Hours</h3>
                </div>
                <div className={style.courseOpportunities}>
                    <p><span>Job Opportunities:</span> Full Stack Developer, Python Web Application Developer, Java Enterprise Application Developer</p>
                </div>
            </div>
        </div>

    {/* Semester 4b */}
        <header className={style.courseHeader}>
            <h2 className={style.courseTitle}>Semester 4B (OST & .NET)</h2>
        </header>
        <div className={style.courseRow}>
            <ul className={style.listRow}>
                {semester4B.map((course, index) => (
                    <li key={index}>{course}</li>
                ))}
            </ul>
            <div className={style.courseDuration}>
                <div className={style.courseHours}>
                    <span>Duration : </span>
                    <h3> 230<br />Hours</h3>
                </div>
                <div className={style.courseOpportunities}>
                    <p><span>Job Opportunities:</span> Full Stack Developer, Python Web Application Developer, .NET Enterprise Application Developer</p>
                </div>
            </div>
        </div>

    {/* Semester 4c */}
        <header className={style.courseHeader}>
            <h2 className={style.courseTitle}>Semester 4C (OST & Oracle)</h2>
        </header>
        <div className={style.courseRow}>
            <ul className={style.listRow}>
                {semester4C.map((course, index) => (
                    <li key={index}>{course}</li>
                ))}
            </ul>
            <div className={style.courseDuration}>
                <div className={style.courseHours}>
                    <span>Duration : </span>
                    <h3> 282<br />Hours</h3>
                </div>
                <div className={style.courseOpportunities}>
                    <p><span>Job Opportunities:</span> Full Stack Developer, Python Web Application Developer, .NET Enterprise Application Developer</p>
                </div>
            </div>
        </div>

    {/* Semester 4d */}
        <header className={style.courseHeader}>
            <h2 className={style.courseTitle}>Semester 4D (OST & Networking)</h2>
        </header>
        <div className={style.courseRow}>
            <ul className={style.listRow}>
                {semester4D.map((course, index) => (
                    <li key={index}>{course}</li>
                ))}
            </ul>
            <div className={style.courseDuration}>
                <div className={style.courseHours}>
                    <span>Duration : </span>
                    <h3> 282<br />Hours</h3>
                </div>
                <div className={style.courseOpportunities}>
                    <p><span>Job Opportunities:</span> Full Stack Developer, Python Web Application Developer, Windows Azure Administrator</p>
                </div>
            </div>
        </div>

    {/* Semester 4e */}
        <header className={style.courseHeader}>
            <h2 className={style.courseTitle}>Semester 4E (AI & ML)</h2>
        </header>
        <div className={style.courseRow}>
            <ul className={style.listRow}>
                {semester4E.map((course, index) => (
                    <li key={index}>{course}</li>
                ))}
            </ul>
            <div className={style.courseDuration}>
                <div className={style.courseHours}>
                    <span>Duration : </span>
                    <h3> 288<br />Hours</h3>
                </div>
                <div className={style.courseOpportunities}>
                    <p><span>Job Opportunities:</span> AI Developer</p>
                </div>
            </div>
        </div>

    {/* Semester 4f */}
        <header className={style.courseHeader}>
            <h2 className={style.courseTitle}>Semester 4F (Data Science)</h2>
        </header>
        <div className={style.courseRow}>
            <ul className={style.listRow}>
                {semester4F.map((course, index) => (
                    <li key={index}>{course}</li>
                ))}
            </ul>
            <div className={style.courseDuration}>
                <div className={style.courseHours}>
                    <span>Duration : </span>
                    <h3> 284<br />Hours</h3>
                </div>
                <div className={style.courseOpportunities}>
                    <p><span>Job Opportunities:</span> Data Analyst, Hadoop Developer</p>
                </div>
            </div>
        </div>

{/* Semester 4g */}
        <header className={style.courseHeader}>
            <h2 className={style.courseTitle}>Semester 4G (Internet of Things)</h2>
        </header>
        <div className={style.courseRow}>
            <ul className={style.listRow}>
                {semester4G.map((course, index) => (
                    <li key={index}>{course}</li>
                ))}
            </ul>
            <div className={style.courseDuration}>
                <div className={style.courseHours}>
                    <span>Duration : </span>
                    <h3> 188<br />Hours</h3>
                </div>
                <div className={style.courseOpportunities}>
                    <p><span>Job Opportunities:</span> IoT Developer</p>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Course