import React from 'react'
import { Helmet } from "react-helmet";
import EventsPage from '../../components/Events/EventsPage'

const Events = () => {
  return (
    <div>
        <Helmet>
           {/* Google tag (gtag.js) for Ad metrics */}
         <script async src="https://www.googletagmanager.com/gtag/js?id=AW-671901233"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-671901233');
          `}
        </script>
          {/* Google tag (gtag.js) for Ad metrics */}
            <meta charset="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta
            name="description"
            content="Aptech Lekki Centre is a franchise of Aptech Computer Education India. We give hands-on training in software engineering: web development, Mobile and Desktop app development and basic computer operation in Lekki Nigeria. We build high performing developers through our rich and outstanding curriculum."
            />
            <meta
            name="keywords"
            content="Tech Events, Career Quest, Aptech Graduation, Middlesex Scholarship, Learn Programming, learn web development in Nigeria, Become a software engineer, Learn mobile application development, frontend development, backend development, Learn HTML, Learn CSS, Learn JavaScript, Learn Python, Learn C#, Learn Java, SQL, Project Management, Digital Marketing"
            />

            <meta
            property="og:title"
            content="Aptech Lekki Centre | Events"
            />
            <meta property="og:url" content="https://aptechlekki.com/" />
            <meta
            property="og:description"
            content="Aptech Lekki Centre is a franchise of Aptech Computer Education India. We give hands-on training in software engineering: web development, Mobile and Desktop app development and basic computer operation in Lekki Nigeria. We build high performing developers through our rich and outstanding curriculum."
            />

            <meta name="robots" content="index, follow" />

        </Helmet>
        <EventsPage />
    </div>
  )
}

export default Events