import React from 'react'

import FAQ from './FAQ'
import style from './css/faq.module.css'

const Faq = [
  {
    question: 'Why Aptech?',
    answer:
      "Aptech is a world pioneer in IT training programs, we offer excellent value and teach up to date I.T skills/modern technology skills.",
  },
  {
    question: 'Why would I choose Aptech over a conventional university?',
    answer:
      'Aptech offers hands-on practical trainings. faculties are being trained on latest technological advancement in different fields in I.T and you can transfer your credits to our UK or Dubai affiliated universities.',
  },
  {
    question: 'What is ADSE?',
    answer:
      'ADSE is an Advanced Diploma in Software Engineering course that has a total of 4 semesters in 2years, it creates a pathway to enable you get a Bsc from our UK, UAE, Malaysia for a year.',
  },
  {
    question: 'What are the advantages of ADSE?',
    answer:
      'Acquire skills for trending jobs. Highly qualified trainers. International university alliances. Credit transfer facility available. Study abroad in UK, Dubai, Malaysia or Malta. Save time & money on international degree.',
  },
  {
    question: 'What is the full meaning of Aptech?',
    answer:
      'Applied Technology',
  },
  {
    question: 'Is Aptech internationally recognised?',
    answer:
      'Aptech is a leading global I.T educational institute with presence in more than 40 countries. All certificate have global recognition.',
  },
  {
    question: 'Can I combine with my work?',
    answer:
      'We offer flexible trainings, as there are online class options which are scheduled to suit working-class individuals.',
  },
  {
    question: 'Is the ADSE/Middlesex University pathway guaranteed?',
    answer:
      'The ADSE/Middlesex University pathway has been in existence for more that 10years and has helped over 3,000 students get their degrees in different computer related courses.',
  },
  {
    question: 'What is the Aptech curriculum like?',
    answer:
      'Aptech offers up to date curriculum as courses are contacted being updated to meet industry demands. Learning materials are impactful and eproje ts given to test student knowledge.',
  },
  {
    question: 'How do I register?',
    answer:
      'Registration is seamless as our career counsellors guide you on how to go about it and help you make the process seamless.',
  },
  {
    question: 'Does Aptech offer short-term courses?',
    answer:
      'Aptech offers short-term courses ranging from basics to professional courses and offers certification as well.',
  },
  {
    question: "I don't have any prior knowledge",
    answer:
      "If you lack prior computer experience, worry not! Aptech Lekki Centre is here to provide comprehensive training, starting from the fundamentals of computer operations. In no time, you'll gain the skills to navigate a computer proficiently.",
  },
]

const FAQSection = () => {
  return (
   <>
    <header className={style.faqHeader} id='faq'>
            <h2 className={style.faqTitle}>Frequently Asked Questions</h2>
            <p className={style.faqText}>Here are some answers to most of our frequently asked questions</p>
    </header>
    <div className={style.faqContainer}>
            {Faq.map((q) => (
              <FAQ key={q.question} {...q} />
            ))}
    </div>
   </>
  )
}

export default FAQSection