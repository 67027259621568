import React from 'react'
import { Helmet } from "react-helmet";
import ContactDetails from '../../components/Contact/ContactDetails'

const Contact = () => {
  return (
    <div>
      <Helmet>
         {/* Google tag (gtag.js) for Ad metrics */}
         <script async src="https://www.googletagmanager.com/gtag/js?id=AW-671901233"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-671901233');
          `}
        </script>
          {/* Google tag (gtag.js) for Ad metrics */}
      <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="description"
          content="2nd Floor, Brasas R Place Admiralty Way, Lekki Phase 1, Lagos Nigeria. Tel:+248134370701, +234814155415. Email:info@aptechlekkicentre.com, aptechlekki@gmail.com. Office Time: 8AM - 5PM Mondays - Fridays"
        />

        <meta
          name="keywords"
          content="Contact Aptech Lekki, Learn Programming, learn web development in Nigeria, Become a software engineer, Learn mobile application development, frontend development, backend development, Learn HTML, Learn CSS, Learn JavaScript, Learn Python, Learn C#, Learn Java, SQL, Project Management, Digital Marketing"
        />

        <meta name="robots" content="index, follow" />
      </Helmet>
      <ContactDetails />
    </div>
  )
}

export default Contact