import React from 'react'
import style from './css/hero.module.css'
import Typewriter from '../TypeWriterText/Typewriter'

const Hero = () => {
  return (
    <div className={style.heroSection}>
        <header className={style.heroContent}>
            <small className={style.heroTinyText}>Aptech trained over 7million students worldwide</small>
            <h1 className={style.heroH1}>Unleash Your <br /><span className={style.orangeText}> <Typewriter text="Potential."/></span></h1>
            <p className={style.heroMainText}>Being technologically literate empowers you to navigate an increasingly digital world and positions you for big opportunities in this modern day. </p>
        </header>
    </div>
  )
}

export default Hero