import React, { useState, useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom';
import style from './css/nav.module.css';

import { HamburgetMenuClose, HamburgetMenuOpen } from './Icons';

import logo from '../assets/logo.png'

function Nav() {
    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);

    const location = useLocation();

    // cause scrolling to the top of the page each time a url is clicked
    useEffect(() => {
        window.scrollTo(0, 0);

        // allowing the element to stay where there is a # in the url e.g faq
        const { hash } = location;
        if (hash) {
            const element = document.getElementById(hash.replace('#', ''));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }

    }, [location.pathname]);

  return (
    <nav className={style.navBar}>
        <div className={style.navContainer}>
            <NavLink exact="true" to="/" className={style.navLogo}>
                <img src={logo} alt="Aptech Logo" />
            </NavLink>
            <ul className={click ? `${style.navMenu} ${style.active}` : `${style.navMenu}`}>
                <li className={style.navItem}><NavLink exact="true" to="/" className={({isActive}) => (isActive ? `${style.active} ${style.navLinks}` : `${style.navLinks}`)} onClick={handleClick}>Home</NavLink></li>
                <li className={style.navItem}><NavLink exact="true" to="/about" className={({isActive}) => (isActive ? `${style.active} ${style.navLinks}` : `${style.navLinks}`)} onClick={handleClick}>About Us</NavLink></li>
                <li className={style.navItem}><NavLink exact="true" to="/courses" className={({isActive}) => (isActive ? `${style.active} ${style.navLinks}` : `${style.navLinks}`)} onClick={handleClick}>Courses</NavLink></li>
                <li className={style.navItem}><NavLink exact="true" to="/contact" className={({isActive}) => (isActive ? `${style.active} ${style.navLinks}` : `${style.navLinks}`)} onClick={handleClick}>Contact</NavLink></li>
                <li className={style.navItem}><NavLink exact="true" to="/events" className={({isActive}) => (isActive ? `${style.active} ${style.navLinks}` : `${style.navLinks}`)} onClick={handleClick}>Events</NavLink></li>
                <li className={style.navItemLast}><NavLink exact="true" to="/apply" className={({isActive}) => (isActive ? `${style.active} ${style.navLinks}` : `${style.navLinks}`)} onClick={handleClick}>Apply</NavLink></li>
            </ul>
            <div className={style.navIcon} onClick={handleClick}>
            {/* <i className={click ? "fas fa-times" : "fas fa-bars"}></i> */}

            {click ? (
              <span className={style.icon}>
                <HamburgetMenuClose />
              </span>
            ) : (
              <span className={style.icon}>
                <HamburgetMenuOpen />
              </span>
            )}
          </div>
        </div>
    </nav>
  )
}

export default Nav