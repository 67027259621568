import React from 'react';
import styles from './css/EventsPage.module.css'; 
import pic1 from '../../assets/events/DSC_0286.JPG'
import pic2 from '../../assets/events/DSC_0287.JPG'
import pic3 from '../../assets/events/DSC_0388.JPG'
import pic4 from '../../assets/events/DSC_0722.JPG'
import pic5 from '../../assets/events/DSC_0657.JPG'
import pic6 from '../../assets/events/DSC_0169.JPG'
import pic7 from '../../assets/events/DSC_0186.JPG'
import pic8 from '../../assets/events/DSC_0233.JPG'
import pic9 from '../../assets/events/DSC_0162.JPG'
import pic10 from '../../assets/events/DSC_0238.JPG'
import pic11 from '../../assets/events/DSC_0240.JPG'
import pic12 from '../../assets/events/DSC_0243.JPG'
import pic13 from '../../assets/events/DSC_0650.JPG'
import pic14 from '../../assets/events/IMG_2584.JPG'
import pic15 from '../../assets/events/DSC_0265.JPG'
import pic16 from '../../assets/events/DSC_0633.JPG'
import pic17 from '../../assets/events/DSC_0797.JPG'
import pic18 from '../../assets/events/DSC_0819.JPG'
import pic19 from '../../assets/events/DSC_0785.JPG'
import pic20 from '../../assets/events/DSC_0720.JPG'
import pic21 from '../../assets/events/IMG_2490.JPG'
import pic22 from '../../assets/events/IMG_2520.JPG'
import pic23 from '../../assets/events/IMG_2523.JPG'
import pic24 from '../../assets/events/IMG_2551.JPG'
import pic25 from '../../assets/events/IMG_3016.JPG'
import pic26 from '../../assets/events/DSC_0781.JPG'
import pic27 from '../../assets/events/IMG_3074a.jpg'

const EventsPage = () => {
    const events = [
        { id: 1, title: 'Graduation 2021', imageUrl: pic1 },
        { id: 2, title: 'Career Quest 2019', imageUrl: pic2 },
        { id: 3, title: 'Graduation 2021', imageUrl: pic3 },
        { id: 4, title: 'Career Quest 2019', imageUrl: pic4 },
        { id: 5, title: 'Career Quest 2019', imageUrl: pic5 },
        { id: 6, title: 'Graduation 2021', imageUrl: pic6 },
        { id: 7, title: 'Graduation 2021', imageUrl: pic7 },
        { id: 8, title: 'Graduation 2021 Most Outstanding Student Award', imageUrl: pic8 },
        { id: 9, title: 'Graduation 2021', imageUrl: pic9 },
        { id: 10, title: 'Career Quest 2022', imageUrl: pic10 },
        { id: 11, title: 'Career Quest 2022', imageUrl: pic11 },
        { id: 12, title: 'Career Quest 2022', imageUrl: pic12 },
        { id: 13, title: 'Career Quest 2022', imageUrl: pic13 },
        { id: 14, title: 'CQ 2022 Scholarship Receipients', imageUrl: pic14 },
        { id: 15, title: 'CQ 2021 Scholarship Receipients', imageUrl: pic15 },
        { id: 16, title: 'Director with MD/CEO Aptech International', imageUrl: pic16 },
        { id: 17, title: 'Miss Aptech Lekki 2019', imageUrl: pic17 },
        { id: 18, title: 'Career Quest 2019 Middlesex University Representative', imageUrl: pic18 },
        { id: 19, title: 'Career Quest 2019 Award', imageUrl: pic19 },
        { id: 20, title: 'CQ 2019 Project Presentation', imageUrl: pic20 },
        { id: 21, title: 'CQ 2019 Middlesex University Presentation', imageUrl: pic21 },
        { id: 22, title: 'Career Quest 2022', imageUrl: pic22 },
        { id: 23, title: 'Career Quest 2022', imageUrl: pic23 },
        { id: 24, title: 'Career Quest 2023 Aptech India Representative', imageUrl: pic24 },
        { id: 25, title: 'Career Quest 2023', imageUrl: pic25 },
        { id: 26, title: 'Winner (Codathon Team 2019)', imageUrl: pic26 },
        { id: 27, title: 'Career Quest 2022', imageUrl: pic27 },
    ];

    return (
        <div className={styles.eventsContainer}>
            {events.map(event => (
                <div key={event.id} className={styles.eventCard}>
                    <img src={event.imageUrl} alt={event.title} className={styles.eventImage} />
                    <h3 className={styles.eventTitle}>{event.title}</h3>
                </div>
            ))}
        </div>
    );
};

export default EventsPage;